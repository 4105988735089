import { createStore } from 'vuex'

export default createStore({
  state: {
    currentChildSort:-1,
    scoreCurrentSort:0,
    scoreCurrentChildSort:0,
    mkName:"",
    flag:'',
  },
  getters: {
  },
  mutations: {
    scoreJump(state,value){
      state.scoreCurrentSort=value.sort
      state.scoreCurrentChildSort=value.sortChild
      state.flag=value.flag
    },
    updateSort(state,value){
      state.currentChildSort=value.sort
      state.mkName=value.name
    },
    resetData(state,value){
      state.currentChildSort=-1
      state.mkName=""
    }
  },
  actions: {
    scoreUpdate:(context,data)=>{
      context.commit("scoreJump",data)
    },
    update:(context,data)=>{
      context.commit("updateSort",data)
    },
    reset:(context,data)=>{
      context.commit("resetData",data)
    }
  },
  modules: {
  }
})

import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import '@/assets/css/commonCss.scss' // 引入很多 公共的css
import '@/assets/css/elementCOM.scss' // 引入很多 公共的css
import '@/assets/iconFont/iconfont.css' // 引入很多 公共的css
import '@/assets/iconFont/iconfont.js' // 引入很多 公共的css
import scroll from "vue-seamless-scroll/src";
import zhCn from 'element-plus/dist/locale/zh-cn.mjs' // element 中文包
import titleSubunit from '@/components/titleSubunit/titleSubunit.vue'
import PlaceHolder from '@/components/placeholder/placeholder.vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import WebsockDiv from "@/components/webSocket/webSocket.vue";
const app= createApp(App)
app.use(store)
    .use(router)
    .use(scroll)
    .use(ElementPlus,{
        locale: zhCn,
    })
    .component('WebsockDiv',WebsockDiv)
    .component('titleSubunit',titleSubunit)
    .component('PlaceHolder',PlaceHolder)
    .mount('#app')
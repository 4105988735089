<template>
    <div
            class="ScreenAdapter"
            :style="style"
    >
        <slot/>
    </div>
</template>

<script setup>
    import {defineProps, ref, reactive,onMounted,getCurrentInstance } from 'vue'
    import { useStore } from "vuex";
    const vue = getCurrentInstance();

    const props = defineProps({
        width: {
            type: String,
            default: '1920'
        },
        height: {
            type: String,
            default: '1080'
        }
    })
    const style = ref({
        'width':  props.width + 'px',
        'height':  props.height + 'px',
        'transform': 'scale(1) translate(-50% -50%)',
    })
    onMounted(() => {
        setScale()
        window.onresize = Debounce(setScale, 1000)
    });
    function Debounce(fn, t) {
        const delay = t || 500
        let timer = null
        return function() {
            const args = arguments
            if (timer) {
                clearTimeout(timer)
            }
            const context = this
            timer = setTimeout(() => {
                timer = null
                fn.apply(context, args)
            }, delay)
        }
    }
    // 获取放大缩小比例
    function   getScale() {
        const w = window.innerWidth / props.width
        const h = window.innerHeight / props.height
        return [w,h]
        // return w < h ? w : h
    }
    function setScale() {
        style.value.transform = 'scale(' + getScale()[0] + ',' + getScale()[1] + ') translate(-50%, -50%)'
    }
</script>

<style scoped>
    .ScreenAdapter {
        transform-origin: 0 0;
        position: absolute;
        left: 50%;
        top: 50%;
        /*transition: 0.3s;*/
        color: #ffffff;
        overflow: hidden;
    }
</style>
